import { AZURE_AD_CLIENT_ID, AZURE_AD_TENANT } from './azureCredentials';

export const msalConfig = {
  auth: {
    clientId: AZURE_AD_CLIENT_ID,
    authority: AZURE_AD_TENANT,
    redirectUri:
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      '/redirect.html',
    postLogoutRedirectUri:
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      '/dashboard',
  },

  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true,
  },

  system: {
    loadFrameTimeout: 30000,
  },
};
/** Note : msal cashing and validating tokens mechanism only works with User.Read scope.
 *  If other scope used, msal will always call api to fetch new token.
 *  Other scopes : ["openid", "profile", "User.Read", "offline_access"]*/
export const loginRequest = {
  scopes: ['User.Read'],
  forceRefresh: false,
};

/*
export const tokenRequest = {
  scopes: [AZURE_AD_CLIENT_ID + '/.default', 'offline_access'],
  forceRefresh: false,
};*/
export const tokenRequest = {
  scopes: [AZURE_AD_CLIENT_ID + '/.default', 'offline_access'],
  forceRefresh: false,
};
