import React, { createContext, useContext, useState, useEffect } from 'react';
import authService from '../Services/AuthService';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthenticationProvider(props) {
  const [authentication, setAuthentication] = useState({
    error: null,
    isAuthenticated: false,
    user: {
      displayName: '',
      email: '',
      roles: [],
    },
  });

  useEffect(() => {
    async function checkIfUserIsAuthenticated() {
      const account = await authService.isUserAuthenticated();
      const isAuthenticated = Object.keys(account).length === 0 ? false : true;
      setAuthentication({
        isAuthenticated: isAuthenticated,
        user: {
          displayName: account?.displayName,
          email: account?.email,
          roles: account?.roles,
        },
        error: null,
      });
      if (!isAuthenticated) {
        authService.clearAllCookies();
      }
    }
    checkIfUserIsAuthenticated();
  }, []);

  const login = async () => {
    const { account } = await authService.login();
    setAuthentication({
      isAuthenticated: account && Object.keys(account).length === 0 ? false : true,
      user: {
        displayName: account?.name,
        email: account?.userName,
        roles: account?.idToken?.roles,
      },
      error: 'Authentication failed',
    });
  };

  const logout = async () => {
    await authService.logout();
    setAuthentication({
      isAuthenticated: false,
      user: {},
      error: null,
    });
  };

  const isUserAuthenticated = () => {
    return authentication.isAuthenticated;
  };

  const requireAccessToken = async () => {
    return await authService.requireAccessToken();
  };

  const { user } = authentication;

  const value = {
    user,
    login,
    logout,
    requireAccessToken,
    isUserAuthenticated,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
