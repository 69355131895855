import { axiosAzureInstance } from '../Utils/axios';
const FileDownload = require('js-file-download');

const sendDocuments = async odcNumber => {
  const { data } = await axiosAzureInstance.get('bugnioninterface/api/documents/' + odcNumber);
  return data;
};

const sendReminders = async reminderName => {
  const { data } = await axiosAzureInstance.get('bugnioninterface/api/reminders/' + reminderName);
  return data;
};

const getReports = async date => {
  const { data } = await axiosAzureInstance.get(
    'bugnioninterface/api/reports/?date=' + formatDate(date)
  );
  return data;
};

const downloadReport = async fileName => {
  const { data } = await axiosAzureInstance.get('bugnioninterface/api/reports/' + fileName, {
    responseType: 'blob',
  });
  FileDownload(data, fileName);
  return data;
};

const formatDate = date => {
  let year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  day.length === 1 && (day = '0' + day);
  month.length === 1 && (month = '0' + month);
  return year + month + day;
};

const BugnionService = {
  sendDocuments,
  sendReminders,
  getReports,
  downloadReport,
};
export default BugnionService;
