import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Link from '@mui/material/Link';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth } from '../../Context/AuthenticationContext';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
    margin: 'auto',
    textAlign: 'left',
  },
  subtitle1: {
    paddingTop: theme.spacing(3),
  },
  subtitle2: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  loginText: {
    paddingTop: theme.spacing(2),
    color: 'red',
  },
  links: {
    paddingTop: theme.spacing(1),
    textDecoration: 'none',
  },
}));

export default function Landing({ loginText }) {
  const { isUserAuthenticated } = useAuth();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid>
        <Grid item>
          <Box>
            <Typography variant="h3">Welcome to the ip-x-change platform</Typography>
            <Box className={classes.loginSubtittle}>
              {!isUserAuthenticated() && (
                <>
                  <Typography variant="h6" className={classes.loginText}>
                    {loginText} <LockOpenIcon></LockOpenIcon>
                  </Typography>
                  <br />
                </>
              )}
            </Box>
            <Typography className={classes.subtitle1}>
              The ip-x-change is the industry’s first open IP platform designed to give access to a
              range of the industry’s best of breed services without complexity. Ip-x-change enables
              users to combine existing software tools with innovative solutions through one simple
              integration.
            </Typography>
            <Box className="w-80">
              <Typography className={classes.subtitle1}>
                The platform has been developed by the ipan group and seamlessly integrates some of
                the industry’s most innovative services and providers. <br />
                In the creation of a true open IP platform, further best-in-class providers will be
                added continuously, selected by the users of the ip-x-change.
              </Typography>
            </Box>
            <Box className="w-80">
              <Typography className={classes.subtitle1}>
                For more information on ipan group, please visit{' '}
                <Link
                  href="https://www.ipan-services.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.ipan-services.com
                </Link>
              </Typography>
            </Box>
            <Box className="w-80">
              <Typography className={classes.subtitle1}>
                For more information on the ip-x-change, please visit{' '}
                <Link underline="hover" href="https://www.ip-x-change.com" target="_blank">
                  www.ip-x-change.com
                </Link>
              </Typography>
            </Box>
          </Box>

          <Box className="w-80">
            <Typography className={classes.subtitle2}>Our other products are: </Typography>
            <ul>
              <li className={classes.links}>
                <Link
                  className={classes.links}
                  href="https://automatch-test.ipan-platform.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Automatch
                </Link>
              </li>
              <li className={classes.links}>
                <Link
                  href="https://smartdocketing-test.ipan-platform.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Smartdocketing
                </Link>
              </li>
              <li className={classes.links}>
                <Link
                  href="https://adv-test.ipan-platform.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ADV
                </Link>
              </li>
              <li className={classes.links}>
                <Link
                  href="https://portfolio-test.ipan-platform.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Portfolio
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
