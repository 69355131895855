import axios from 'axios';
import authService from '../Services/AuthService';
import { toast } from 'react-toastify';

export const axiosAzureInstance = axios.create();

axiosAzureInstance.interceptors.request.use(async function (config) {
  const token = await authService.fetchNewAccessToken();
  console.log(Object.keys(token).length);
  if (Object.keys(token).length === 0) {
    toast.warn('Your session has expired.', {
      position: 'top-right',
      autoClose: true,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: 0,
    });
    setTimeout(() => {
      authService.logout();
    }, 2000);
    return;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const axiosInstance = axios.create({
  withCredentials: false,
});
