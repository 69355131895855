import memoize from 'lodash.memoize';
import WebScraperService from '../Services/WebScraperService';

/** function to get and cashe data from api
 * It will call api for the first time it's invoked, after that  it wont call api, insted it will use cashed data
 */
const getMemoizedData = async () => {
  const { data } = await WebScraperService.getAvailableCountries();
  return data;
};

export default memoize(getMemoizedData);
