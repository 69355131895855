import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import BugnionDocuments from './BugnionDocuments';
import BugnionReminders from './BugnionReminders';
import BugnionReports from './BugnionReports';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
    margin: 'auto',
    textAlign: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const Bugnion = () => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container className={classes.container}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Reports" />
          <Tab label="Reminders" />
          <Tab label="Documents" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BugnionReports />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BugnionReminders />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BugnionDocuments />
      </TabPanel>
    </Container>
  );
};

export default Bugnion;
