import React, { useState } from 'react';
import BugnionService from '../../../Services/BugnionService';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';

const BugnionReminder = () => {
  const [reminderName, setReminderName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSendRemindersClick = async () => {
    try {
      setLoading(true);
      console.log(reminderName);
      await BugnionService.sendReminders(reminderName);
      setReminderName('');
      toast.success(`Reminder number ${reminderName} successfully processed.`, {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
    } catch (err) {
      err?.response?.data?.message
        ? setError(err.response.data.message)
        : setError('Unexpected error occured');

      toast.error(`Unexpected error occured. Please try later (description : ${err.message})`, {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid direction="column" alignItems="center" container columnSpacing={3}>
      <Grid item>
        <TextField
          type="text"
          name="reminderName"
          placeholder="Reminder number"
          variant="standard"
          maxLength={50}
          onChange={e => setReminderName(e.target.value)}
          value={reminderName}
          required
          sx={{ input: { textAlign: 'center' }, display: 'block', marginTop: '20pt' }}
          helperText={error}
          error={error === '' ? false : true}
          onClick={() => {
            setError('');
            setReminderName('');
          }}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          variant="contained"
          loading={loading}
          style={{ marginTop: '20pt' }}
          onClick={() => handleSendRemindersClick()}
          disabled={loading || reminderName === ''}
        >
          Send reminders
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default BugnionReminder;
