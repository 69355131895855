import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="col-md-12 text-center">
      <span className="display-1 d-block">404</span>
      <div className="mb-4 lead">The page you are looking for was not found.</div>
      <Link to="/dashboard" className="btn btn-link">
        Back to Home
      </Link>
    </div>
  );
};

export default NotFound;
