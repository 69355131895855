import React, { useState, useEffect } from 'react';
import WebScraperService from '../../../Services/WebScraperService';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import FileUploadIcon from '@mui/icons-material/UploadFile';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import getMemoizedData from '../../../Utils/getMemoizedData';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  dropzone: {
    textAlign: 'center',
    padding: '20pt',
    border: '3pt dashed #eeeeee',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    marginBottom: '20pt',
  },
});

const WebScrapperUploadFile = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [countries, setAvailableCountries] = useState({
    data: [],
    isLoading: true,
  });
  const classes = useStyles();

  useEffect(() => {
    let isSubscribed = true;
    async function fetchAvailableCountries() {
      try {
        const countries = await getMemoizedData();
        if (isSubscribed && countries) {
          setAvailableCountries({ data: countries, isLoading: false });
        }
      } catch (ex) {
        toast.error(`Unexpected error occured. Please try later (description : ${ex.message})`, {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
        });
      }
    }

    fetchAvailableCountries();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleFileUpload = acceptedFiles => {
    setFile(acceptedFiles[0]);
  };

  const handleCountrySelect = ({ target }) => {
    const { value } = target;
    setCountry(value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(country.trim());
    setLoading(true);
    const data = new FormData();
    data.append('file', file);
    const reportRequest = {
      country: country.trim() || 'AT',
      file: data,
    };
    try {
      const { status } = await WebScraperService.scrapeCountry(reportRequest);
      if (status === 200) {
        toast.info('You have submitted request. Please get results from Overview Tab', {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
        });
      }
    } catch (error) {
      toast.error('Unexpected error occured. Please try again.', {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="countryChooseLbl">
              {countries.isLoading ? 'Loading..' : 'Choose country'}
            </InputLabel>
            <Select
              onChange={handleCountrySelect}
              label="Choose country"
              labelId="countryChooseLbl"
            >
              {!countries.isLoading &&
                countries.data.map(item => {
                  return (
                    <MenuItem key={item.code} value={item.code}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <Dropzone
            maxFiles={1}
            onDrop={handleFileUpload}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <p>{"Drag'n'drop files, or click to select files"}</p>
                <em>{'(Only *.xlsx files will be accepted)'}</em>
                <FileUploadIcon style={{ display: 'block', margin: '5pt auto' }} />
              </div>
            )}
          </Dropzone>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <List dense={true}>
            {file && (
              <ListItem key={file.path}>
                <FilePresentIcon />
                <ListItemText primary={file.name}></ListItemText>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            style={{ margin: '0 auto' }}
            type="submit"
            variant="contained"
            loading={file && loading}
            disabled={!file || loading}
          >
            Start
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default WebScrapperUploadFile;
