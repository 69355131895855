import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthenticationProvider } from './Context/AuthenticationContext';
import * as serviceWorker from './serviceWorker';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme();

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </ThemeProvider>
    <ToastContainer
      autoClose={3000}
      pauseOnHover={false}
      position="top-right"
      closeOnClick={true}
      closeButton={false}
      transition={Slide}
    />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
