import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../Context/AuthenticationContext';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { isUserAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={props => {
        return isUserAuthenticated() ? <Component {...props} /> : <Redirect to="/dashboard" />;
      }}
    ></Route>
  );
}
