import * as Msal from 'msal';
import { msalConfig } from '../authConfig';
import { loginRequest, tokenRequest } from '../authConfig';
import { setCookie, getCookie, removeAllCookies } from '../Utils/cookieUtils';
import { isTokenExpired, getUserData } from '../Utils/tokenUtil';

const msalInstance = new Msal.UserAgentApplication(msalConfig);

/* These callback functions are required if we use login with redirect. (msal.loginRedirect())

 function authCallback(error, response) {
   //handle redirect response
   console.log(response);
   console.log(error);
 }

 msalInstance.handleRedirectCallback(authCallback); */

const login = async () => {
  try {
    const account = await msalInstance.loginPopup(loginRequest);
    setCookie('a_token', account.idToken.rawIdToken);
    return account;
  } catch (err) {
    console.log(err);
    return {
      account: {},
    };
  }
};

const logout = () => {
  try {
    msalInstance.logout();
  } catch (err) {
    console.log(err);
  } finally {
    clearAllCookies();
  }
};

const isUserAuthenticated = async () => {
  const user = getUserData();
  return user;
};

const fetchNewAccessToken = async () => {
  try {
    var token = getCookie('a_token');
    if (!isTokenExpired(token)) {
      return token;
    }
    let account = await msalInstance.getAccount();
    tokenRequest.account = account;
    const newToken = await msalInstance.acquireTokenSilent(tokenRequest);
    if (newToken && newToken.accessToken) {
      setCookie('a_token', newToken.accessToken);
      return newToken.accessToken;
    }
    if (newToken && newToken.idToken && newToken.idToken.rawIdToken) {
      setCookie('a_token', newToken.idToken.rawIdToken);
      return newToken.idToken.rawIdToken;
    }
    return {};
  } catch (err) {
    console.log('exception');
    console.log(err);
    if (isInteractionRequired(err)) {
      const response = await msalInstance.loginPopup(loginRequest);
      if (response && response.accessToken) {
        setCookie('a_token', response.accessToken);
        return response.accessToken;
      }
      if (response && response.idToken && response.idToken.rawIdToken) {
        setCookie('a_token', response.idToken.rawIdToken);
        return response.idToken.rawIdToken;
      }
    }
    return {};
  }
};

function isInteractionRequired(error) {
  if (!error.message || error.message.length <= 0) return false;
  return (
    error.message.indexOf('consent_required') > -1 ||
    error.message.indexOf('interaction_required') > -1 ||
    error.message.indexOf('login_required') > -1 ||
    error.message.indexOf('no_account_in_silent_request') > -1
  );
}

export const clearAllCookies = () => {
  try {
    removeAllCookies();
  } catch (err) {
    console.log(err);
  }
};

const AuthService = {
  login,
  logout,
  isUserAuthenticated,
  fetchNewAccessToken,
  clearAllCookies,
};

export default AuthService;
