import jwt from 'jsonwebtoken';
import { getCookie } from './cookieUtils';

export function isTokenExpired(token) {
  if (!token) return true;
  const decodedToken = jwt.decode(token);
  if (decodedToken.exp && Date.now() + 10000 <= decodedToken.exp * 1000) {
    console.log('token is not expired');
    return false;
  } else {
    console.log('token is expired');
    return true;
  }
}

export function getUserData() {
  const token = getCookie('a_token');
  if (!isTokenExpired(token)) {
    const decodedToken = jwt.decode(token);
    const user = {
      displayName: decodedToken?.name,
      email: decodedToken?.email,
      roles: decodedToken?.roles,
    };
    return user;
  }
  return {};
}
