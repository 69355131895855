import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import StyledTableCell from '../../../Utils/tableUtils';
import BugnionService from '../../../Services/BugnionService';

const BugnionReportsTable = ({ data }) => {
  const handleDownload = async fileName => {
    await BugnionService.downloadReport(fileName.replace('reports/', ''));
  };

  return (
    <div className="bugnion-reports-table">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Report name</StyledTableCell>
              <StyledTableCell>Size</StyledTableCell>
              <StyledTableCell>Download</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow key={row.name} hover={true}>
                <TableCell>{row.name.replace('reports/', '')}</TableCell>
                <TableCell>{row.size} B</TableCell>
                <TableCell>
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none"
                    onClick={() => handleDownload(row.name)}
                  >
                    <DownloadIcon />
                    Download
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BugnionReportsTable;
