import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './Components/Security/PrivateRoute';
import Landing from './Components/Layout/Landing';
import NotFound from './Components/Layout/NotFound';
import WebScrapper from './Components/Project/WebScraper/WebScrapper';
import NavigationBar from './Components/Layout/Header/NavigationBar';
import BatchID from './Components/Project/BatchID/BatchID';
import Bugnion from './Components/Project/Bugnion/Bugnion';
import './App.css';

function App() {
  return (
    <Router>
      <NavigationBar />
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={props => (
            <Landing {...props} loginText="Before proceeding to use portal, please login" />
          )}
        />
        <PrivateRoute exact path="/" component={Landing} />
        <PrivateRoute exact path="/component_webscraper" component={WebScrapper} />
        <PrivateRoute exact path="/batchid" component={BatchID} />
        <PrivateRoute exact path="/bugnion" component={Bugnion} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
