//errors
export const GET_ERRORS = 'GET_ERRORS'; //Generic-other errors
export const WEBSCRAPER_ERRORS = 'WEBSCRAPER_ERRORS';
export const SMSCTEST_ERRORS = 'SMSCTEST_ERRORS';
export const BATCHID_ERRORS = 'BATCHID_ERRORS';

//web scraper
export const WEB_SCRAPER_SUCCESS = 'WEB_SCRAPER_SUCCESS';
export const WEB_SCRAPER_INITIAL_STATE = 'WEB_SCRAPER_INITIAL_STATE';

//security
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UAA = 'UAA';
export const AD = 'AD';
export const CERT_PREFIX = '-----BEGIN CERTIFICATE-----\n';
export const CERT_SUFFIX = '\n-----END CERTIFICATE-----';

//Azure authority
export const URL = window.location.href;
const ENV =
  URL.includes('dev') || URL.includes('localhost')
    ? 'dev'
    : URL.includes('test')
    ? 'test'
    : URL.includes('staging')
    ? 'staging'
    : 'prod';
export const ENV_WITHOUT_LOCALHOST = URL.includes('dev')
  ? 'dev'
  : URL.includes('test')
  ? 'test'
  : URL.includes('staging')
  ? 'staging'
  : 'prod';
const AZURE_AD_CLIENT_IDS = {
  dev: 'ab358112-dc28-48ec-b3e9-f42845e90591',
  test: 'ab358112-dc28-48ec-b3e9-f42845e90591',
  staging: '664072f3-2607-4635-a81b-ca781669b640',
  prod: '79652d9e-71e0-4d2c-9632-937dc84c522b',
};
export const AZURE_AD_CLIENT_ID = AZURE_AD_CLIENT_IDS[ENV];
const AZURE_AD_TENANT_IDS = {
  dev: '418936ac-99e4-47e6-b784-9b4e6bb31bdc',
  test: '418936ac-99e4-47e6-b784-9b4e6bb31bdc',
  staging: '127fa96e-00b4-429e-95f9-72c2828437a4',
  prod: '127fa96e-00b4-429e-95f9-72c2828437a4',
};

export const AZURE_AD_TENANT = 'https://login.microsoftonline.com/'.concat(
  AZURE_AD_TENANT_IDS[ENV]
);
export const AZURE_AD_ACCESS_TOKEN_ISSUER = 'https://sts.windows.net/'
  .concat(AZURE_AD_TENANT_IDS[ENV])
  .concat('/');
export const AZURE_AD_ID_TOKEN_ISSUER = 'https://login.microsoftonline.com/'
  .concat(AZURE_AD_TENANT_IDS[ENV])
  .concat('/v2.0');
export const JWKS_URI = 'https://login.microsoftonline.com/'
  .concat(AZURE_AD_TENANT_IDS[ENV])
  .concat('/discovery/v2.0/keys?appid=')
  .concat(AZURE_AD_CLIENT_ID);

//batch id
export const BATCHID = 'BATCHID';
const GENERATE_BATCH_ID_ENDPOINTS = {
  dev: 'https://ptoproxy.dev.ipan-platform.com/api/batch-id',
  test: 'https://ptoproxy.test.ipan-platform.com/api/batch-id',
  staging: 'https://ptoproxy-staging.ipan-platform.com/api/batch-id',
  prod: 'https://ptoproxy.ipan-platform.com/api/batch-id',
};

export const GENERATE_BATCH_ID_ENDPOINT = GENERATE_BATCH_ID_ENDPOINTS[ENV];

const GOOGLE_ANALITYCS_ID_LIST = {
  dev: 'UA-172316551-1',
};

export const GOOGLE_ANALITYCS_ID = GOOGLE_ANALITYCS_ID_LIST[ENV_WITHOUT_LOCALHOST];

export const INACTIVITY_WARNING = 'Warning: you will be logged-out due to inactivity';
export const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 mins
export const INACTIVITY_WARNING_TIMEOUT = 5 * 60 * 1000; // 5 mins
