import { GENERATE_BATCH_ID_ENDPOINT } from '../azureCredentials';
import { axiosAzureInstance } from '../Utils/axios';

const fetchBatchId = async () => {
  try {
    const { data } = await axiosAzureInstance.get(GENERATE_BATCH_ID_ENDPOINT);

    return data;
  } catch (err) {
    console.log(err);
  }
};

const BatchIdService = {
  fetchBatchId,
};

export default BatchIdService;
