import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../../../logo.png';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useAuth } from '../../../Context/AuthenticationContext';
import Button from '@mui/material/Button';
import { ArrowDownwardOutlined } from '@mui/icons-material';
import StyledMenu from './StyledMenu';

const useStyles = makeStyles({
  link: {
    color: 'rgba(0,0,0,.5)',
    padding: '.5rem 1rem',
    boxSizing: 'border-box',
    fontSize: '1rem',
    '&:hover': {
      color: '#565656',
      textDecoration: 'none',
    },
  },
});

const NavigationBar = () => {
  const classes = useStyles();
  const { logout, isUserAuthenticated, user, login } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = async () => {
    await login();
    setAnchorEl(false);
  };
  const displayUserEmail = () => {
    if (user && user.email) {
      return user.email;
    }
    return '';
  };
  const handleLogout = async () => {
    await logout();
  };

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#ececec' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link to="/dashboard">
              <img src={logo} alt="Clarivate" component={Link} to="/dashboard" />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Link
                to="/component_webscraper"
                className={classes.link}
                style={{
                  display: user?.roles?.indexOf('WEB_SCRAPER') > -1 ? 'inline-block' : 'none',
                }}
              >
                Web scraper
              </Link>
              <Link
                to="/batchid"
                className={classes.link}
                style={{
                  display:
                    user?.roles?.indexOf('AMS_PAYMENTS') > -1 ||
                    user?.roles?.indexOf('PRS_PAYMENTS') > -1 ||
                    user?.roles?.indexOf('TM1_PAYMENTS') > -1
                      ? 'inline-block'
                      : 'none',
                }}
              >
                Batch ID
              </Link>
              <Link
                to="/bugnion"
                className={classes.link}
                style={{
                  display: user?.roles?.indexOf('BUGNION_INTERFACE') > -1 ? 'inline-block' : 'none',
                }}
              >
                Bugnion
              </Link>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <img src={logo} alt="Clarivate" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Link
              to="/component_webscraper"
              className={classes.link}
              style={{
                display: user?.roles?.indexOf('WEB_SCRAPER') > -1 ? 'inline-block' : 'none',
              }}
            >
              Web scraper
            </Link>
            <Link
              to="/batchid"
              className={classes.link}
              style={{
                display:
                  user?.roles?.indexOf('AMS_PAYMENTS') > -1 ||
                  user?.roles?.indexOf('PRS_PAYMENTS') > -1 ||
                  user?.roles?.indexOf('TM1_PAYMENTS') > -1
                    ? 'inline-block'
                    : 'none',
              }}
            >
              Batch ID
            </Link>
            <Link
              to="/bugnion"
              className={classes.link}
              style={{
                display: user?.roles?.indexOf('BUGNION_INTERFACE') > -1 ? 'inline-block' : 'none',
              }}
            >
              Bugnion
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {isUserAuthenticated() && (
              <>
                <Button
                  style={{
                    color: 'rgba(0,0,0,.5)',
                    textTransform: 'lowercase',
                  }}
                  onClick={handleClick}
                  endIcon={<ArrowDownwardOutlined />}
                >
                  {displayUserEmail()}
                </Button>
                <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem onClick={handleLogout} disableRipple>
                    <LogoutIcon />
                    Logout
                  </MenuItem>
                </StyledMenu>
              </>
            )}
            {!isUserAuthenticated() && (
              <Button
                style={{
                  color: 'rgba(0,0,0,.5)',
                }}
                onClick={handleLogin}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavigationBar;
