import React, { useState } from 'react';
import BugnionService from '../../../Services/BugnionService';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';

const BugnionDocuments = () => {
  const [odcNumber, setOdcNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSendDocumentsClick = async () => {
    try {
      setLoading(true);
      await BugnionService.sendDocuments(odcNumber);
      setOdcNumber('');
      toast.success(`ODC number ${odcNumber} successfully processed.`, {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
      });
    } catch (err) {
      console.log('Error:');
      err?.response?.data?.message
        ? setError(err.response.data.message)
        : setError('Unexpected error occured');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid direction="column" container alignItems="center" columnSpacing={10}>
      <Grid item>
        <TextField
          type="text"
          name="odcNumber"
          maxLength={50}
          onChange={e => setOdcNumber(e.target.value)}
          value={odcNumber}
          required
          variant="standard"
          placeholder="ODC number"
          sx={{ input: { textAlign: 'center' }, display: 'block', marginTop: '20pt' }}
          helperText={error}
          error={error}
          onClick={() => {
            setError('');
            setOdcNumber('');
          }}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          variant="contained"
          loading={loading}
          style={{ marginTop: '20pt' }}
          onClick={() => handleSendDocumentsClick()}
          disabled={loading || odcNumber === ''}
        >
          Send documents
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default BugnionDocuments;
