import { axiosAzureInstance } from '../Utils/axios';
import FileDownload from 'js-file-download';

export const scrapeCountry = async request => {
  return await axiosAzureInstance.post(
    `/webscraper-node/api/scrape?country=${request.country}`,
    request.file
  );
};

export const getAvailableCountries = async () => {
  return await axiosAzureInstance.get('/webscraper-node/api/countries');
};

export const getScrapingResults = async (page, size) => {
  return await axiosAzureInstance.get(`/webscraper-node/api/overview?page=${page}&size=${size}`);
};

export const downloadResult = async (id, fileName) => {
  const { data } = await axiosAzureInstance.get(`/webscraper-node/api/download/${id}`, {
    responseType: 'blob',
  });
  FileDownload(data, 'result_' + fileName);
  return data;
};

const WebScraperService = {
  scrapeCountry,
  getAvailableCountries,
  getScrapingResults,
  downloadResult,
};

export default WebScraperService;
