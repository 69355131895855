import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import BatchIdService from '../../../Services/BatchIdService';
import { eventGoogleAnalytics, BATCH_ID_CATEGORY } from '../../../Utils/googleAnalytics';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(15),
    margin: 'auto',
    textAlign: 'center',
    display: 'block',
  },
}));

export default function BatchID() {
  const [batchId, setBatchId] = useState('');
  const [loading, setLoading] = useState(false);
  let mounted = useRef(true);

  const classes = useStyles();

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const handleGenerateBatchId = async () => {
    try {
      setBatchId('');
      setLoading(true);
      const response = await BatchIdService.fetchBatchId();
      if (response && mounted) {
        const { batchId } = response;
        setBatchId(batchId);
        eventGoogleAnalytics(BATCH_ID_CATEGORY, 'Batch id generated');
      }
    } catch (err) {
      console.error(err);
      setBatchId('Error: No Connection. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className={classes.container}>
      <TextField
        disabled
        placeholder="Batch ID"
        value={loading ? 'Generating Batch ID...' : batchId}
        sx={{ input: { textAlign: 'center' }, display: 'block' }}
        InputProps={{
          readOnly: true,
        }}
        variant="standard"
      />
      <LoadingButton
        variant="contained"
        style={{ marginTop: '20pt' }}
        loading={loading}
        onClick={() => handleGenerateBatchId()}
      >
        Generate Batch ID
      </LoadingButton>
    </Container>
  );
}
