import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import BugnionService from '../../../Services/BugnionService';
import BugnionReportsTable from './BugnionReportsTable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LinearProgress from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';

const BugnionReports = () => {
  const [date, setDate] = useState(new Date());
  const [showSpinner, setShowSpinner] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    async function getReports() {
      setShowSpinner(true);
      try {
        const reports = await BugnionService.getReports(date);
        if (isSubscribed && reports) setReports(reports);
      } catch (err) {
        console.log(err);
        toast.error(
          `Unexpected error occured. Please try again later (description : ${err.message})`,
          {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
          }
        );
      } finally {
        if (isSubscribed) setShowSpinner(false);
      }
    }
    getReports();
    return () => {
      isSubscribed = false;
    };
  }, [date]);

  const noDataForSelectedDate = 'There is no data for selected date.';
  const reportTable =
    reports.length === 0 ? noDataForSelectedDate : <BugnionReportsTable data={reports} />;

  const handleDateChange = newValue => {
    setDate(newValue);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            mask="__-__-____"
            label="Select date"
            inputFormat="MM-dd-yyyy"
            value={date}
            maxDate={new Date()}
            onChange={handleDateChange}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
        {showSpinner ? <LinearProgress></LinearProgress> : <></>}
      </Grid>
      <Grid item style={{ marginTop: '30pt', width: '100%' }}>
        {!showSpinner ? reportTable : <></>}
      </Grid>
    </Grid>
  );
};

export default BugnionReports;
