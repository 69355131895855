import ReactGA from 'react-ga';
import { ENV_WITHOUT_LOCALHOST, GOOGLE_ANALITYCS_ID } from '../azureCredentials';

export const WEB_SCRAPER_CATEGORY = 'Web Scraper';
export const BATCH_ID_CATEGORY = 'Batch id';

export const initGoogleAnalytics = () => {
  //remove condition after adding other environments
  if (ENV_WITHOUT_LOCALHOST === 'dev') ReactGA.initialize(GOOGLE_ANALITYCS_ID);
};

export const setPageViewGoogleAnalytics = pagePath => {
  ReactGA.set({ page: pagePath });
  ReactGA.pageview(pagePath);
};

export const eventGoogleAnalytics = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
  });
};
