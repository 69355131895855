import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WebScrapperOverview from './WebScrapperOverview';
import WebScrapperUploadFile from './WebScrapperUploadFile';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
    margin: 'auto',
    textAlign: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

export default function WebScraper() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className={classes.container}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="File upload" />
          <Tab label="Overview" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <WebScrapperUploadFile></WebScrapperUploadFile>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WebScrapperOverview></WebScrapperOverview>
      </TabPanel>
    </Container>
  );
}
