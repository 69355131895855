import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import WebScraperService from '../../../Services/WebScraperService';
import Button from '@mui/material/Button';
import StyledTableCell from '../../../Utils/tableUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 'auto',
    padding: '2pt',
    margin: '5pt',
  },
  cell: {
    maxWidth: '130pt',
    minWidth: '70pt',
    padding: '10pt !important',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center !important',
  },
});

const headerColumns = [
  { id: 'country', label: 'Country' },
  { id: 'user', label: 'User' },
  { id: 'cratedDate', label: 'Time Stamp' },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'fileName',
    label: 'File Name',
  },
  {
    id: 'successRows',
    label: '#succeeded',
  },
  {
    id: 'failedRows',
    label: '#failed',
  },
  {
    id: 'download',
    label: 'Download',
  },
];

const WebScrapperOverview = () => {
  const classes = useStyles();
  const [scrapeResult, setScrapeResult] = useState({
    data: [],
    totalSize: 0,
    totalPages: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleChangePage = async (event, newPage) => {
    /**Pagination starts from 1, and this is handeled on server side */
    try {
      setLoading(true);
      const { data } = await WebScraperService.getScrapingResults(newPage + 1, rowsPerPage);
      const { result, totalSize, totalPages } = data;
      setLoading(false);
      setScrapeResult({
        data: result,
        totalSize: totalSize,
        totalPages: totalPages,
      });
      setPage(newPage);
    } catch (err) {
      toast.error(
        `Unexpected error occured. Please try again later (description : ${err.message})`,
        {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRowsPerPage = async event => {
    try {
      setLoading(true);
      const { data } = await WebScraperService.getScrapingResults(1, event.target.value);
      const { result, totalSize, totalPages } = data;
      setRowsPerPage(event.target.value);
      setPage(0);
      setLoading(false);
      setScrapeResult({
        data: result,
        totalSize: totalSize,
        totalPages: totalPages,
      });
    } catch (err) {
      toast.error(
        `Unexpected error occured. Please try again later (description : ${err.message})`,
        {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (id, fileName) => {
    await WebScraperService.downloadResult(id, fileName);
  };

  useEffect(() => {
    let isSubscribed = true;
    async function fetchScrapingResults(page, pageSize) {
      try {
        setLoading(true);
        const response = await WebScraperService.getScrapingResults(page, pageSize);
        if (response) {
          setLoading(false);
          const { data } = response;
          const { result, totalSize, totalPages } = data;
          if (isSubscribed) {
            setScrapeResult({
              data: result,
              totalSize: totalSize,
              totalPages: totalPages,
            });
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    }
    fetchScrapingResults(1, 10);
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <Paper className={classes.container}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map(column => (
                <StyledTableCell key={column.id} style={{ textAlign: 'center' }}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!loading && scrapeResult.totalSize == 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <b>No data to display</b>
                </TableCell>
              </TableRow>
            ) : (
              scrapeResult.data.map(row => {
                return (
                  <TableRow hover tabIndex={-1} key={row.code}>
                    {headerColumns.map(column => {
                      const value = row[column.id];
                      if (value === 'Done') {
                        return (
                          <TableCell
                            key={column.id}
                            className={classes.cell}
                            style={{ color: 'green' }}
                          >
                            {value}
                          </TableCell>
                        );
                      }
                      if (value === 'In progress') {
                        return (
                          <TableCell
                            key={column.id}
                            className={classes.cell}
                            style={{ color: 'orange' }}
                          >
                            {value}
                          </TableCell>
                        );
                      }
                      if (value === 'Failed') {
                        return (
                          <TableCell
                            key={column.id}
                            className={classes.cell}
                            style={{ color: 'red' }}
                          >
                            {value}
                          </TableCell>
                        );
                      }
                      if (column.id === 'user') {
                        return (
                          <TableCell key={column.id} className={classes.cell}>
                            {value.substring(0, value.lastIndexOf('@')) === ''
                              ? 'Unknown user'
                              : value.substring(0, value.lastIndexOf('@'))}
                          </TableCell>
                        );
                      }
                      if (column.id === 'cratedDate') {
                        return (
                          <TableCell key={column.id} className={classes.cell}>
                            {moment.utc(value).utcOffset(60).format('YYYY-MM-DD HH:mm:ss')}
                          </TableCell>
                        );
                      }
                      if (column.id === 'download' && row.status === 'Done') {
                        return (
                          <TableCell key={column.id} className={classes.cell}>
                            <Button onClick={() => handleDownload(row._id, row.fileName)}>
                              <DownloadIcon />
                              Download
                            </Button>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} className={classes.cell}>
                          <Tooltip title={value}>
                            <span>{value}</span>
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={scrapeResult.totalSize}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
            margin: '1rem auto',
          },
        }}
      />
    </Paper>
  );
};

export default WebScrapperOverview;
